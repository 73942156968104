
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// import AddinvoiceDrawer from '@/layout/header/partials/course/AddinvoiceDrawer.vue';
// import EditinvoiceInfoDrawer from '@/layout/header/partials/course/EditinvoiceInfoDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';
import moment from "moment";
import * as Yup from 'yup';

export default defineComponent({
  name: 'invoice-list',
  components: {
    Datatable,
    Form,
    Field,
    ErrorMessage,
    // AddinvoiceDrawer,
    // EditinvoiceInfoDrawer,
  },
  data() {
    return {
      submitButton:true,
      load: false,
      componentKey: 0,
      moment: "" as any,
      loading: false,
      not_found:false,
      invoice: [] as any,
      customerList: [] as any,
      salesmanList: [] as any,
      cashBankList: [] as any,
      trnx_media_options: [
        {
          id:1,
          name:'Cheque',
        },
        {
          id:2,
          name:'Advise',
        },
        {
          id:3,
          name:'Cash',
        },
        {
          id:4,
          name:'Deposite',
        },
        {
          id:5,
          name:'BEFTN',
        },
        {
          id:6,
          name:'RTGS',
        },
        {
          id:7,
          name:'bKash',
        },
        {
          id:8,
          name:'Nagad',
        },
        {
          id:9,
          name:'Rocket',
        }
      ],
      formData: {
        bank_ledger_id: '',
        customer_id:'',
        salesman_id:'',
        bill_amount:'',
        bill_date: '',
        remarks: '',
        trnx_media:'',
        money_receipt_number:'',
        bill_collector_id: '',
        total_due_amount:''
      } as any,
      searchData: {
        customer_id: '',
        salesman_id:'',
      } as any,

      tableHeader: [
        {
          name: 'Invoice Number',
          key: 'invoice_no',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'Customer Name',
          key: 'customer_name',
          // sortable: true,
        },
        // {
        //   name: 'Salesman Name',
        //   key: 'salesman_name',
        //   // sortable: true,
        // },
        {
          name: 'Date',
          key: 'date',
          // sortable: true,
        },
        {
          name: 'SO Number',
          key: 'so_number',
          // sortable: true,
        },
        {
          name: 'DO Number',
          key: 'do_number',
          // sortable: true,
        },
        {
          name: 'Bill',
          key: 'grand_total',
          // sortable: true,
        },
        {
          name: 'Due Total',
          key: 'due_total',
          // sortable: true,
        },
        {
          name: 'Due Date',
          key: 'due_date',
          // sortable: true,
        },

        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      data: {},
      total_due_amount:0,
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      this.formData.bill_collector_id = VueCookieNext.getCookie('user_id');
      this.moment = moment;
      await this.getCustomerList();
      await this.getSalesmanList();
      await this.getCashBankList();
        this.emitter.on('invoice-added', async () => {
        // await this.getinvoiceData();
      });
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    async getCustomerList() {
      await ApiService.get('finmanInvoice/customer_list')
        .then((response) => {
          this.customerList = response.data.data;
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getSalesmanList() {
      await ApiService.get('finmanInvoice/salesman/list')
        .then((response) => {
          this.salesmanList = response.data.data;
          this.componentKey += 1;
          console.log(this.salesmanList);
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },

    async getCashBankList() {
      await ApiService.get(
        'finmanInvoice/ledger/getCashBankLedgers'
      )
        .then((response) => {
          this.cashBankList = response.data.data;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },

    calculateDue()
    {
      this.formData.due_amount= Number(this.total_due_amount - this.formData.bill_amount);
    },
    async getinvoiceData() {
      let customer_id= this.searchData.customer_id;
      // let salesman_id= this.searchData.salesman_id;
      this.formData.customer_id= this.searchData.customer_id;


      // this.formData.salesman_id= this.searchData.salesman_id;
      if(!customer_id)
      {
        return alert('Please Select From All Required Fields');
      }
      await ApiService.get('finmanInvoice/invoice/searchBillingInvoices/' + `${customer_id}`)
        .then((response) => {
          this.tableData = response.data.data;
          let total_due_amount= this.tableData.reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue['due_total']),
              0
          );
          this.formData.due_amount= total_due_amount;
          this.total_due_amount= total_due_amount;
          this.formData.total_due_amount= total_due_amount;
          if(this.tableData.length==0){
            this.not_found=true;
          }
          else
          {
            this.not_found=false;
          }
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },

    collectAll()
    {
      this.formData.bill_amount= this.total_due_amount;
      this.calculateDue();
    },

    addInvoice(){
      this.$router.push('add-invoice');
    },

    viewInvoice(invoice_id) {
      let invoiceData=[] as any;
      ApiService.get("finmanInvoice/invoice/getInvoiceDetails/"+ `${invoice_id}`)
        .then((response) => {
          invoiceData= response.data.data;
          this.emitter.emit('sales-view-type', invoiceData);
        }).catch(({ response }) => {
              console.log(response);
        });
    },
    

    Deleteinvoice(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/invoice/deleteSale/' + `${id}`)
            .then((response) => {
              this.emitter.emit('invoice-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
 
    async formSubmit() {
      this.submitButton= false;
      let sendformData = new FormData();
      sendformData= this.formData;

      await ApiService.post('finmanInvoice/billCollection', sendformData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200 && response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.searchData = {
                customer_id: '',
                salesman_id: '',
              };
              this.formData= {
                bank_ledger_id: '',
                customer_id:'',
                salesman_id:'',
                bill_amount:'',
                bill_date: '',
                remarks: '',
                trnx_media:'',
                money_receipt_number:'',
                bill_collector_id: '',
              };
              this.tableData=[];
              this.$router.push('/bill-collection');
            });
          } else {
            this.submitButton=true;
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html:
              response?.data?.error ||
              'Unknown error occured. Please check all the required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
